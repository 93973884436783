// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  countryCode: 'ALL',
  countryList:[],
  ContractActionType:{type:'CREATE',accountId:25,step:3},
  naviBlocker: {naviBlock:false},
  selectedYear: new Date().getFullYear(),
  pdfConfig:[],
  documentData:{}
};

// ==============================|| SLICE - Common  ||============================== //

const commonRedux = createSlice({
  name: "commonRedux",
  initialState,
  reducers: {
    getSelectedDocumentData(state,action){
      state.documentData = action.payload.documentData || {}
    },
    confirmationNaviBlocker(state, action) {     
      state.naviBlocker.naviBlock = action.payload || false;
    }, 
    activeCountryCode(state, action) {
      state.countryCode = action.payload.countryCode;
    },  
    selectedYearValue(state,action){
      state.selectedYear = action.payload.selectedYear;
    },
    getCountryList(state, action) {
      state.countryList = action.payload.countryList;
    },
    getContractActionType(state, action) {
      state.ContractActionType = {type:action.payload.type,contratId:action.payload.contratId,step:action.payload.id};
    },  
    getPdfDownLoadableConfigData(state,action){
      state.pdfConfig = [action.payload]
    }
  }
});

export default commonRedux.reducer;

export const { getSelectedDocumentData,getPdfDownLoadableConfigData,confirmationNaviBlocker, selectedYearValue, activeCountryCode,getCountryList ,getContractActionType} = commonRedux.actions;
